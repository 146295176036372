@import "/src/styles/breakpoints.scss";

@font-face {
  font-family: "Gotham";
  src: url("../../assets/fonts/GothamRounded-Medium.woff2") format("woff2"),
    url("../../assets/fonts/GothamRounded-Medium.woff") format("woff"),
    url("../../assets/fonts/GothamRounded-Medium.ttf") format("woff");
}

.Tittle {
  text-align: center;
  padding-top: 0px;
  @include w768 {
    margin-top: 5rem !important;
    font-weight: 500 !important;
    padding-top: 40px;
  }
}

.Background {
  background-image: url(../../assets/images/backN.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: calc(100vh - 150px);
  text-align: center;
  @include w768 {
    height: 451px;
  }

  .Copy {
    text-align: center;
    color: #000;
    position: absolute;
    bottom: 15px;
    width: 100%;
  }

  .Subtittle {
    text-align: center;
    padding-top: 30px;
    color: white;
    width: 80%;
  }
}

.containermiddle {
  display: flex;

  .divmiddle {
    padding-top: 50px;
    width: 25%;
    text-align: center;
    padding-left: 20px;
  }

  .boxFather {
    box-sizing: content-box;
    width: 100%;
    padding-top: 20px;

    .box {
      box-sizing: content-box;
      border: solid #ff3c2e 10px;
      padding: 5px;
      text-align: center;
    }
  }
}

label {
  display: block;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  padding-right: 2px;
  padding-top: 20px;
  text-align: left;
  font-size: 5mm;
}

.Cuadro {
  margin-top: 45px;
  background-color: rgb(249, 252, 250);
  text-align: -webkit-center;

  .Title {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    font-size: 6mm;
  }

  .Form {
    width: 80%;

    .Input {
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      box-sizing: border-box;
      border: none;
      background-color: #d6f1cd;
      color: rgb(7, 7, 7);
      font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
      font-size: 5mm;
    }

    .ButtonD {
      margin-top: 25px;
    }
  }

  .Color {
    color: black;
  }

  .Forgot {
    padding-top: 50px;
    padding-left: 10%;
    text-align: left;

    .Link {
      font-family: Cambria, Cochin, Georgia, Times, "Times";
      font-size: 4mm;
      padding-top: 15px;
    }
  }
}

.form {
  padding: 5px 10px 20px 10px;
  border-radius: 6px;
  text-align: center;

  .Days {
    text-align: center;
    border: dashed;
    width: 20%;
    margin: auto;
  }

  .Flex {
    display: flex;
    flex-flow: wrap;
  }

  .MiddleData {
    display: flex;
    margin-top: 1em;
    place-content: center;
    width: 50%;

    .Input {
      margin-left: 5%;
      padding: 8px 10px;
      border: none;
      background-color: #e8f0fe;
      color: rgb(0, 0, 0);
      width: 40px;
    }

    .LabelInput {
      font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
      font-size: 18px;
      margin-left: 2px;
    }
  }

  .Label {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }

  .Button {
    padding-top: 50px;
    width: 100%;
    cursor: pointer;
    margin-right: 0.25em;
    margin-top: 1em;
    padding: 0.938em;
    border: none;
    border-radius: 4px;
    background-color: #229954;
    color: #fefefe;
  }

  .ButtonReset {
    padding-top: 50px;
    width: 50%;
    cursor: pointer;
    padding: 0.938em;
    border-radius: 4px;
    background-color: #229954;
    color: #fefefe;
  }

  .Titles {
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
}

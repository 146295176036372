.PaddingUp {
  padding-top: 40px;
}

.form {
  min-width: 100px;
  min-height: 400px;
  padding: 20px 40px 40px 40px;
  border-radius: 6px;
  box-shadow: 0px 8px 36px rgb(216, 214, 214);
  background-color: #e5ecf0;

  .Title {
    padding-top: 5px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
  }

  .Kilos {
    padding-top: 25px;
  }

  .Cuadros {
    margin-top: 25px;
    margin-block-end: 20px;

    .TittleKilos {
      font-size: 20px;
      text-align: center;
      background-color: #69d3ee;
      font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    }

    .TittleIMC {
      font-size: 20px;
      text-align: center;
      background-color: #ecde9c;
      font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    }

    .TittleGrasa {
      font-size: 20px;
      text-align: center;
      background-color: #a8f5b2;
      font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    }

    .ResultadosKilos {
      text-align: center;
    }
  }
}

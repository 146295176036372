$screen-768-min: 768px;
$screen-1024-min: 1024px;

@mixin w768 {
  @media (max-width: #{$screen-768-min}) {
    @content;
  }
}

@mixin w1024 {
  @media (max-width: #{$screen-1024-min}) {
    @content;
  }
}

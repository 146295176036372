.Loader {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.LoaderDocumentation {
  position: relative;
}

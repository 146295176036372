.hh1 {
  padding-top: 50px;
  color: rgb(49, 94, 6);
  font-family: "Righteous";
  font-size: 200px;
  font-weight: normal;
  line-height: 60px;
  margin: 10px 0 20px;
  text-transform: uppercase;
  text-shadow: 2px 2px 0 #000;
  text-align: center;
}

.None {
  text-align: center;
  background-color: red;
  color: white;
  font-size: 20px;
}

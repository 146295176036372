.PaddingUp {
  padding-top: 40px;
}
.field {
  height: 30;
}

.Relleno {
  padding-top: 25px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.SubTitle {
  text-align: center;
  width: 100%;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: white;
  background-color: yellowgreen;
}

.form {
  flex-flow: wrap;
  display: flex;
  width: 80%;
  min-width: 100px;
  min-height: 400px;
  padding: 20px 15px 30px 15px;
  border-radius: 6px;
  box-shadow: 0px 8px 36px rgb(216, 214, 214);
  background-color: #fefefe;

  .middle {
    width: 80%;
    padding-left: 25px;

    .Sexo {
      display: flex;
      padding-top: 10px;
    }
  }

  .dates {
    width: 100%;
    flex-flow: wrap;
    display: flex;

    .middlethird {
      width: 25%;
      padding-left: 25px;
    }
  }

  .title {
    padding-top: 30px;
    width: 100%;
    padding-block-end: 12px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .Input {
    width: 100%;
    padding: 6px 10px;
    margin: 8px 0;
    box-sizing: border-box;
    border: none;
    background-color: #e8f0fe;
    font-size: 16px;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  }

  .Label {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    font-size: 18px;
  }

  .button {
    padding-top: 50px;
    width: 100%;
    cursor: pointer;
    margin-right: 0.25em;
    margin-top: 1em;
    padding: 0.938em;
    border: none;
    border-radius: 4px;
    background-color: #229954;
    color: #fefefe;
  }
  .required {
    color: #db2269;
    font-size: 0.8em;
    margin-top: 0.5em;
  }
}

.OneSection {
  padding-top: 10px;
  margin-left: 5px;

  .TextColor {
    color: #2b4402;
    font-family: Georgia, "Times New Roman", Times, serif;
    padding-block-end: 20px;
    text-align: center;
  }

  .TextColorGrasa {
    color: #2b4402;
    font-family: Georgia, "Times New Roman", Times, serif;
    padding-block-end: 20px;
    text-align: center;
  }

  .Conce {
    padding-top: 20px;
    text-align: center;
  }

  .Descr {
    width: 100%;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }

  .Third {
    padding: 5px;
    display: flex;
  }

  .FlexAll {
    display: flex;
    padding-top: 8px;
  }

  .Ltter1 {
    margin-left: 15px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }

  .Ltter {
    margin-left: auto;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }

  .Right {
    text-align: right;
    display: flex;
  }
  .BoxColorBlue {
    display: flex;
    height: 20px;
    width: 20px;
    background-color: #2e86c1;
    text-align: center;
  }
  .BoxColorGreen {
    display: flex;
    height: 20px;
    width: 20px;
    background-color: #28b463;
    text-align: center;
  }
  .BoxColorGreenI {
    display: flex;
    height: 20px;
    width: 20px;
    background-color: #07e425;
    text-align: center;
  }
  .BoxColorYellow {
    display: flex;
    height: 20px;
    width: 20px;
    background-color: #d4ac0d;
    text-align: center;
  }
  .BoxColorOrange {
    display: flex;
    height: 20px;
    width: 20px;
    background-color: #ca6f1e;
    text-align: center;
  }
  .BoxColorRed {
    display: flex;
    height: 20px;
    width: 20px;
    background-color: #922b21;
    text-align: center;
  }
}

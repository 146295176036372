.PaddingUp {
  padding-top: 40px;
}

.field {
  height: 30;
}

.form {
  flex-flow: wrap;
  padding: 5px 10px 20px 10px;
  border-radius: 6px;
  box-shadow: 0px 8px 36px rgb(216, 214, 214);
  background-color: #fefefe;

  .EndTitle {
    text-align: center;
    padding-top: 30px;
    margin-top: 1em;
  }

  .RowPlayer {
    display: flex;
    background-color: #e9f1eb;

    .MiddleData {
      width: 50%;

      .Input {
        width: 80%;
        margin-left: 10%;
        padding: 8px 10px;
        border: none;
        background-color: #6be247;
        color: rgb(0, 0, 0);
      }

      .LabelInput {
        font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
          "Lucida Sans", Arial, sans-serif;
        font-size: 18px;
        margin-left: 10%;
      }
    }

    .MiddleFoto {
      padding-top: 15px;
      width: 50%;
      text-align: center;

      .TextPlayer {
        width: 72%;
        padding-top: 5px;
        text-align: center;
        padding-block-end: 5px;
        font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
          "Lucida Sans", Arial, sans-serif;
      }
    }
  }

  .title {
    padding-top: 5px;
    text-align: center;
    width: 100%;
    padding-block-end: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .Label {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }

  .button {
    padding-top: 50px;
    width: 100%;
    cursor: pointer;
    margin-right: 0.25em;
    margin-top: 1em;
    padding: 0.938em;
    border: none;
    border-radius: 4px;
    background-color: #229954;
    color: #fefefe;
  }
}

.FiltersContainer {
  position: relative;
  background: #000;
  color: #fff;
  padding: 20px 15px 40px 15px;

  .Button {
    right: 0;
    bottom: 0;
    position: absolute;
    border: 1px solid #054620;
    cursor: pointer;

    &:disabled {
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;
      cursor: not-allowed;
      pointer-events: all;

      &:hover {
        background-color: #cccccc;
      }
    }
  }

  .ClearButton {
    @extend .Button;
    right: 140px;
  }

  .FilterLabel {
    margin-right: 15px;
  }
}

.MultiSelectValueContainer {
  display: flex;
  flex-wrap: wrap;
}

.MultiSelectDeleteAllValuesIcon {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 3%;
  cursor: pointer;
}

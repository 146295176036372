@import "/src/styles/breakpoints.scss";

.appbarLogo {
  width: 120px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logout {
  color: #a8a8a8;
  display: flex;
  border: 1.5px solid #a8a8a8;
  padding: 4px 15px;
  border-radius: 30px;
  text-transform: uppercase;
  align-self: self-end;

  p {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }
}

.pageTitles {
  background-color: #229954;
  border-radius: 50px;
  padding: 5px 2% 5px 5%;
  width: 95%;
  margin: auto;
  box-sizing: border-box;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  @include w768 {
    border-radius: 19px;
    max-height: 3.375rem;
    min-height: 2.375rem;
  }

  h6 {
    color: #fff;
    @include w768 {
      font-size: 1rem;
      font-weight: 700;
      line-height: 19px;
      align-self: center;
      max-width: 220px;
    }
  }

  .subtitle {
    background: rgba(255, 255, 255, 0.15);
    display: inline-block;
    padding: 0 10px;
    border-radius: 4px;
    line-height: 2rem;
    font-size: 0.8rem;
    @include w768 {
      font-size: 0.6875rem;
      line-height: 0.8125rem;
      font-weight: 500;
      text-align: right;
      width: 100px;
      background-color: transparent;
    }
  }
}

.HeaderTitlesContainer {
  display: flex;
  align-items: center;

  .Title {
    position: absolute;
    left: 60px;
  }

  .PageTitle {
    margin: auto;
  }

  .UserName {
    padding-right: 30px;
    color: black;
  }

  @include w1024 {
    flex-direction: column;
    text-align: center;

    .Title {
      position: relative;
      left: 0;
      margin-bottom: 20px;
    }

    .PageTitle {
      margin: unset;
      margin-bottom: 20px;
    }

    .UserName {
      padding-right: 30px;
      color: black;
      padding-left: 30px;
    }
  }
}

.swipeableIcon {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
  display: flex;

  div {
    height: 20px;
    width: 3px;
    border-radius: 10px;
    background: #a9a9a9;
    margin: 0 3px;
  }
}

.PaddingUp {
  padding-top: 40px;
}
.field {
  height: 30;
}

.form {
  flex-flow: wrap;
  display: flex;
  width: 80%;
  min-width: 100px;
  min-height: 400px;
  padding: 20px 40px 40px 40px;
  border-radius: 6px;
  box-shadow: 0px 8px 36px rgb(216, 214, 214);
  background-color: #fefefe;

  .middle {
    width: 45%;
    padding-left: 25px;
  }

  .dates {
    width: 100%;
    flex-flow: wrap;
    display: flex;

    .middlethird {
      width: 25%;
      padding-left: 25px;
    }
  }

  .title {
    padding-top: 30px;
    width: 100%;
    padding-block-end: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .Input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: none;
    background-color: #e8f0fe;
  }

  .Label {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    font-size: 18px;
  }

  .button {
    padding-top: 50px;
    width: 100%;
    cursor: pointer;
    margin-right: 0.25em;
    margin-top: 1em;
    padding: 0.938em;
    border: none;
    border-radius: 4px;
    background-color: #229954;
    color: #fefefe;
  }
  .required {
    color: #db2269;
    font-size: 0.8em;
    margin-top: 0.5em;
  }
}
